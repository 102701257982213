import {defineComponent} from "vue";

export default defineComponent({
	data() {
		return {
			input: null as HTMLInputElement | null,
			inputValue: '' as string | boolean | FileList,
			errorElement: null as HTMLElement | null,
			errors: 0,
			errorObj: {
				requiredError: false,
				formatError: false,
			},
			formatCheckValues: ['minlength', 'maxlength', 'pattern'],
			formKeys: [],
		}
	},
	methods: {
		hasErrors(nest: string | null = null): boolean {
			this.errors = 0;
			this.checkErrors(nest);
			return this.errors > 0;
		},
		resetVariables(): void {
			this.input = null;
			this.inputValue = '';
			this.errorElement = null;
			this.errorObj = {
				requiredError: false,
				formatError: false,
			};
		},
		checkErrors(nest: string | null = null): void {
			let _formKeys = this.formKeys;
			if (nest) {
				_formKeys = this.formKeys[nest];
			}

			let checkedRequiredFields = [];
			const _requireedFields = this.requiredFields ?? {};
			const _computedFormKeys = this.computedFormKeys ?? [];


			// check requeired
			if (Object.keys(_requireedFields).length === 0) {
				checkedRequiredFields = this.formKeys;
			}

			if (this.formKeys.length === 0) {
				checkedRequiredFields = _computedFormKeys ?? [];
				_formKeys = _computedFormKeys;
			}
			
			if (Object.keys(_requireedFields).length > 0) {
				checkedRequiredFields = Object.keys(_requireedFields).filter(key => _requireedFields[key]);
			}
			checkedRequiredFields.forEach((key: string) => {
				const isContains = _formKeys.includes(key);
				if (isContains) {

					this.resetVariables(); // kinullázzuk mindent
					this.initInput(key); // inicializáljuk az input-ot és az error elemet
					this.initInputValue(); // inicializáljuk az input értékét
					this.resetInputError(); // levesszük az input-ról az error osztályt és kiürítjük az error elem szövegét

					// incializáljuk az error-okat
					if (this.input) {
						this.getInputError();
					}
					// beállítjuk az error-okat
					this.setInputError();
				}
			});
		},
		initInput(key: string): void {
			this.input = document.querySelector('#' + key) as HTMLInputElement;
			this.errorElement = document.querySelector('#' + key + '_info') as HTMLElement;
		},
		initInputValue(): void {
			if (this.input && this.input?.type === 'checkbox') {
				this.inputValue = this.input?.checked;

			} else if (this.input && this.input?.getAttribute('type') === 'radio-btn') {
				this.inputValue = (this.input.querySelector('input[type="radio"]:checked') as HTMLInputElement)?.value;

			} else if (this.input && this.input?.getAttribute('type') === 'birth-date') {
				this.inputValue =
					[...this.input.querySelectorAll('select')]     // kikeressük mind a 3 select-et, ami a komponensben van
						.map(select => select.value)     // csak az értékükre van szükségünk
						.filter(v => v)                              // de ha nincs értékük, akkor ezt kiszűrjük
						.join('-');											// ha van értékük, akkor összekapcsoljuk a tömb egyes elemeit kötőjellel

			} else if (this.input && this.input.classList.contains('v-select')) {
				this.inputValue = this.input.querySelector('span.vs__selected')?.textContent ?? '';

			} else if (this.input) {
				this.inputValue = this.input.value;
			}
		},
		resetInputError(): void {
			if (this.input) {
				this.input?.classList.remove('error');
			}
			if (this.errorElement) {
				this.errorElement.innerText = "";
				this.errorElement.style.display = "none";
			}
		},
		getInputError(): void {
			// beállítjuk a required error-t
			if (this.input?.hasAttribute('required') === true || this.input?.getAttribute('required') === 'true') {
				this.errorObj.requiredError = typeof this.inputValue === 'boolean' ? !this.inputValue : (!this.inputValue && !this.inputValue?.toString().length);
				if (this.errorObj.requiredError) this.errors++;
			}

			// ha nincs required error, akkor beállítjuk a minlength/maxlength/pattern error-okat
			if (!this.errorObj.requiredError && this.inputValue && this.inputValue?.toString().length > 0) {
				this.formatCheckValues.forEach(val => {
					if (this.input?.hasAttribute(val)) {
						const formatValue = this.input?.getAttribute(val);
						if (formatValue) {
							let regex;
							switch (val) {
								case 'minlength':
									regex = new RegExp('^.{' + formatValue + ',}$');
									break;
								case 'maxlength':
									regex = new RegExp('^.{0,' + formatValue + '}$');
									break;
								case 'pattern':
									regex = new RegExp(formatValue);
									break;
							}
							if (!regex?.test(this.inputValue.toString())) {
								this.errorObj.formatError = true;
								this.errors++;
							}
						}
					}
				});
			}
		},
		setInputError(): void {
			// ha van bármilyen error, akkor beállítjuk az input error osztályát és kiírjuk az error üzenetet
			if (this.errorObj.requiredError || this.errorObj.formatError) {
				this.input?.classList.add('error');
			}
			if (this.errorObj.requiredError && this.errorElement) {
				this.errorElement.innerText = this.translate('error-1');
				this.errorElement.style.display = "block";

				if (this.input) {
					const elementPosition = this.input.getBoundingClientRect().top + (window.scrollY - 200);
					window.scrollTo({top: elementPosition, behavior: 'smooth'});
				}
			}
			if (this.errorObj.formatError && this.errorElement) {
				this.errorElement.innerText = this.translate('error-1');
				this.errorElement.style.display = "block";
			}
		},

		realTimeValidation(key: string): void {
			this.resetVariables();
			this.initInput(key);
			this.initInputValue();
			this.resetInputError();
			if (this.input) {
				this.getInputError();
			}
			this.setInputError();
		},
	},
});
