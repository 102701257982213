<template>
	<template v-if="this.options?.name && this.options?.name?.length > 0">
		<label
				:for="this.options?.slug"
				class="input-unit__label"
				:class="{'required': this.options?.required}"
		>
			{{ this.options?.name }}
		</label>
	</template>

	<input
			:ref="this.options?.slug"
			:class="{
				'input-unit': true,
				'error': this.options?.errorState ?? false,
				[this.options?.class ?? '']: true,
			}"
			:type="this.options?.type ?? 'text'"
			:name="this.options?.slug"
			:readonly="this.options?.readonly ?? false"
			:id="this.options?.slug"
			:value="this.modelValue"
			:placeholder="this.options?.placeholder ?? ''"
			:minlength="this.options?.minlength ?? null"
			:maxlength="this.options?.maxlength ?? null"
			:pattern="this.options?.pattern ?? null"
			:required="this.options?.required ?? false"
			:disabled="this.options.disabled ?? false"
			v-maska="this.maskOptions"
			@input="this.inputChange($event)"
			@click="handleClick"
	/>

	<small
			v-show="this.options?.infoText && this.options?.infoText?.length > 0"
			:id="this.options?.slug+'_info'"
			class="mt-1 d-flex input-unit__label--secondary"
	>
		{{ this.options?.infoText }}
	</small>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {InputUnitOptions} from "./Interfaces/InputUnitOptions";
import InputErrorHandler from "../../Mixins/InputErrorHandler";

export default defineComponent({
	name: "InputUnit",
	emits: ['clickToInput'],
	mixins: [InputErrorHandler],
	props: {
		options: {
			type: Object as PropType<InputUnitOptions>,
			required: true,
		},
		modelValue: {
			type: [String, Number],
			default: '',
			required: true,
		},
	},
	mounted() {
		if (this.options?.autofocus) {
			(this.$refs[this.options?.slug] as HTMLElement)?.focus();
		}
	},
	computed: {
		maskOptions(): object | null {
			if (this.options.mask) {
				return {
					mask: this.options.mask,
					tokens: this.options.maskTokens,
				}
			} else {
				return null;
			}
		},
	},
	methods: {
		inputChange(ev: any): void {
			if (this.options?.realTimeValidation) {
				this.realTimeValidation(this.options.slug);
				this.$emit("update:modelValue", ev.target.value);
			} else {
				this.$emit("update:modelValue", ev.target.value);
			}
		},
		handleClick(e: any) {
			this.$emit('clickToInput', e);
		}
	}
});
</script>

<style scoped>
</style>
